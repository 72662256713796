.App {
  padding-left: 10%;
  padding-right: 10%;
}

p {
  font-size: 30px;
  margin-top: 0;
}

.bold {
  font-weight: bold;
}

img {
  height: 150px
}